.main-container {
  background-color: #fff;
  padding: 25px;
  min-height: 100%;
}

.core-inner-frame {
  min-width: 960px;
}

.text-eclipse {
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
}

.text-eclipse-3 {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  width: 100%;
}

.thumbnail {
  &-list {
    height: 56px;
    width: 134px;
    overflow: hidden;
    position: relative;

    img {
      position: absolute;
      // height: 56px;
      width: 100%;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }

  &-lists {
    display: flex;
    max-width: 405px;
    &-list {
      flex: 1;
    }
  }
}
