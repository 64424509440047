.CURDTable {
  // margin: 20px;
  border-radius: 5px;
  // background-color: #fff;

  &_header {
    // margin-bottom: 20px;
    display: flex;
    align-items: center;

    &_action {
      // margin-top: 10px;
      position: relative;
      top: 1px;
    }

    &_query {
      // margin-top: 20px;
    }
  }

  &_body {
    margin-top: 10px;
  }

  &_footer {
    text-align: center;
    padding: 10px 0;
  }
}

#components-table-demo-drag-sorting tr.drop-over-downward td {
  border-bottom: 2px dashed #1890ff;
}

#components-table-demo-drag-sorting tr.drop-over-upward td {
  border-top: 2px dashed #1890ff;
}
