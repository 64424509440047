@import './config';

.header {
  height: $headerHeight;
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: space-between;
  background-color: #fff;
  flex-shrink: 0;
  box-shadow: 0 1px 4px rgba(0, 21, 41, 0.08);
  padding: 0 24px;

  &-trigger {
    font-size: 20px;
    transition: all 0.3s, padding 0s;
    cursor: pointer;
    margin-right: 30px;
  }

  &__left {
    display: flex;
    align-items: center;
  }

  &__right {
    display: flex;
    flex-wrap: nowrap;
    font-size: 14px;

    &-item {
      line-height: $headerHeight;
      height: $headerHeight;
      cursor: pointer;
    }

    &-item + &-item {
      margin-left: 20px;
    }
  }

  &-user {
    span {
      margin-right: 8px;
    }
  }
}
