.content_body {
  width: 220px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;

  .row {
    height: 90px;
  }
}
