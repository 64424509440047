.uploadIcon {
  font-size: 32px;
  color: #999;
}

.uploadText {
  margin-top: 8px;
  color: #666;
}

:global {
  .fire-uploader {
    .ant-upload-list-picture-card .ant-upload-list-item-info {
      display: flex;
      align-items: center;
    }
  }
}

.main_container_lessonImage {
  display: inline-block;
  margin: 0;
  margin-right: 10px;
  width: 110px;
  height: 110px;
  margin-bottom: 8px;
  text-align: center;
  line-height: 104px;
  vertical-align: middle;
  background-color: #fafafa;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  cursor: pointer;
  position: relative;

  &_img {
    width: 90%;
    height: 90%;
  }

  &_loading {
    width: 100%;
    height: 104px;
    top: 0;
    position: absolute;
    transition: opacity 1s;

    &_text,
    &_progress {
      position: absolute;
      top: -15px;
      left: 0;
      width: 100%;
    }

    &_progress {
      top: 8px;
    }
  }

  &:hover {
    .mask {
      display: flex;
    }
  }

  .mask {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: #000;
    opacity: 0.3;
    display: none;
    cursor: pointer;
    color: #fff;
    font-size: 18px;
    justify-content: center;
    align-items: center;

    &_item {
      margin-right: 15px;
    }

    &_item:last-of-type {
      margin-right: 0;
    }
  }
}

.previewImage_modal {
  :global {
    .ant-modal-content {
      background-color: unset !important;
      box-shadow: none !important;
    }

    .anticon-close.ant-modal-close-icon {
      color: #ddd;

      &:hover {
        color: #fff;
      }
    }

    .ant-modal-body {
      text-align: center;
    }
  }

  &_prev,
  &_next {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    font-size: 44px;
    color: #aaa !important;
    -moz-user-select: none; /*火狐*/
    -webkit-user-select: none; /*webkit浏览器*/
    -ms-user-select: none; /*IE10*/
    -khtml-user-select: none; /*早期浏览器*/
    user-select: none;

    &:hover {
      color: #ddd !important;
    }
  }

  &_prev {
    left: 0;
  }

  &_next {
    right: 0px;
  }

  &_img_body {
    width: 500px;
    height: 500px;
    display: inline-block;
    max-height: 67vh;
    text-align: center;
    line-height: 500px;

    img {
      max-width: 100%;
      max-height: 98%;
    }
  }
}
