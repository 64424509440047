@import '~antd/lib/style/index';
@import '~antd/lib/message/style/index';
@import '~antd/lib/button/style/index';
@import '~antd/lib/form/style/index';
@import '~antd/lib/grid/style/index';
@import '~antd/lib/input/style/index';
@import '~antd/lib/dropdown/style/index';
@import '~antd/lib/layout/style/index';
@import '~antd/lib/menu/style/index';
@import '~antd/lib/select/style/index';
@import '~antd/lib/radio/style/index';
@import '~antd/lib/tree-select/style/index';
@import '~antd/lib/tree/style/index';
@import '~antd/lib/tooltip/style/index';
@import '~antd/lib/popover/style/index';
@import '~antd/lib/descriptions/style/index';
@import '~antd/lib/breadcrumb/style/index';
@import '~antd/lib/input-number/style/index';
@import '~antd/lib/date-picker/style/index';
@import '~antd/lib/tag/style/index';
@import '~antd/lib/table/style/index';
@import '~antd/lib/time-picker/style/index';
@import '~antd/lib/pagination/style/index';
@import '~antd/lib/spin/style/index';
@import '~antd/lib/cascader/style/index';
@import '~antd/lib/card/style/index';
@import '~antd/lib/modal/style/index';
@import '~antd/lib/divider/style/index';
@import '~antd/lib/checkbox/style/index';
@import '~antd/lib/switch/style/index';

@import '~@/styles/theme/webkit-scrollbar';

// 设置自定义滚动条

.ant-table-scroll {
  .ant-table-body {
    &:extend(.webkit-scrollbar all);
    overflow-y: auto !important;
  }
}

.ant-table-body-inner {
  &:extend(.webkit-scrollbar all);
  overflow-y: auto !important;
}

.ant-layout {
  &:extend(.webkit-scrollbar all);
}

[class^='ant-table-'] {
  .ant-table-body-outer {
    margin-bottom: 0 !important;
  }
}

body {
  &:extend(.webkit-scrollbar all);
}

.ant {
  // &-table-thead {
  //   >tr>th {
  //     padding: 6px 15px;
  //   }
  // }

  // &-table-body {
  //   overflow: auto !important;
  // }
  &-table-tbody > tr > td {
    height: 50px;
    font-size: 13px;
  }

  &-table-tbody {
    tr:nth-of-type(even) {
      background-color: #f8f8f8;
    }
  }

  &-table-fixed-left {
    box-shadow: 3px 0 3px -1px rgba(0, 0, 0, 0.15);
  }

  &-table-fixed-right {
    box-shadow: -3px 0 3px -1px rgba(0, 0, 0, 0.15);
  }

  // &-table-fixed {
  //   table-layout: fixed;
  // }
}
