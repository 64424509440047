.webkit-scrollbar {
  overflow: auto;

  &::-webkit-scrollbar {
    width: 9px;
    height: 9px;
  }

  &::-webkit-scrollbar:horizontal {
    // height: 0;
  }

  &::-webkit-scrollbar:vertical {
    // height: 0;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 8px;
    background-color: rgba(0, 0, 0, 0.3);
    -webkit-box-shadow: -2px 0 0 #fff inset, 1px 0 0 #fff inset,
      0 -1px 0 rgba(255, 255, 255, 0.9) inset,
      0 1px 0 rgba(255, 255, 255, 0.9) inset;

    &:hover {
      background-color: rgba(0, 0, 0, 0.5);
    }

    &:active {
      background: rgba(0, 0, 0, 0.6);
    }
  }

  &::-webkit-scrollbar-track {
    border-radius: 8px;
    background-color: rgba(0, 0, 0, 0);

    &:hover {
      background-color: rgba(0, 0, 0, 0.1);
      -webkit-box-shadow: -2px 0 0 #fff inset,
        1px 0 0 rgba(255, 255, 255, 0.9) inset,
        0 -1px 0 rgba(255, 255, 255, 0.9) inset,
        0 1px 0 rgba(255, 255, 255, 0.9) inset;
    }

    &:active {
      background-color: rgba(0, 0, 0, 0.16);
    }
  }
}
