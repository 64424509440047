@import './_config';

$animationDuration: 0.4s;

.menu {
  min-height: 100vh;
  transition: width $animationDuration ease-in-out;
  box-shadow: 2px 0 6px rgba(0, 21, 41, 0.35);
  background: $menubgColor;

  &.mini {
    .menu__header-name {
      display: none;
    }
  }

  &__header {
    padding: 0 20px;
    background: $menuLogobgColor;
    color: #fff;
    height: $headerHeight;
    line-height: $headerHeight;
  }
}
